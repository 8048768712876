import React, { Component } from 'react'

import ReactPlayer from "react-player/file"
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Container from '../components/container/container'
import Contact from '../components/contact/contact'

import './css/testimonials.css';

export default class Testimonials extends Component {

    componentDidMount() {
        document.title = 'Testimonials | IDenteq'
    }

    render() {
        return (
            <div>
                <Header page="testimonials" />
                {/* Testimonials content */}
                <Container>

                    {/* Client Testimonials */}
                    <div id="Testimonials_clientTestimonials" class="testimonials_row">

                        <div id="Testimonials_header_wrap">
                            <h2>Client Testimonials</h2>
                            <p>These are just a few of the solutions we provide for our partners and the impact they are having. <a href="/contact/">Contact us</a> to find out what we could do for your team.</p>
                        </div>

                        <div id="Testimonials_clientTestimonials_content_wrap">

                            <div id="Testimonials_clientTestimonials_vid">
                                <div className="video-container">
                                    <ReactPlayer url="https://cotportal-videos-live.s3.eu-west-2.amazonaws.com/identeq_-_mark_severn_trent_water_-_testimonial+(2160p).mp4" playing controls width="100%" height="390.234px" light="/images/severn-trent-water.avif"  playIcon={<PlayCircleOutlineIcon style={{ fontSize: '120px', color: 'white' }} />} />
                                </div>
                            </div>

                            <div id="Testimonials_clientTestimonials_text_wrap">
                                <h2>Severn Trent Water</h2>
                                <p>Severn Trent Water were grappling with higher than average void property numbers, which necessitated a cost-effective solution. <strong>IDenteq emerged as an ideal partner</strong> due to our ability to process large amounts of data in bulk, providing flexibility for Severn Trent Water.</p>
                                <br />
                                <p>By leveraging IDenteq’s technology and advice, <strong>Severn Trent Water achieved a substantial 30% reduction in void property numbers,</strong> resulting in cost savings that could be passed on to customers.</p>
                            </div>
                        </div>

                    </div>
                    {/* Client Testimonials - END */}

                    {/* Wave */}
                    <div id="Testimonials_Wave" class="testimonials_row">
                        <div id="Testimonials_Wave_img_wrap">
                            <div id="Testimonials_Wave_img_margin">
                                <div className="video-container">
                                    <ReactPlayer url="https://cotportal-videos-live.s3.eu-west-2.amazonaws.com/identeq_wave_testimonial+(2160p)+(1).mp4" playing controls width="100%" height="100%" light="/images/wave.avif"  playIcon={<PlayCircleOutlineIcon style={{ fontSize: '120px', color: 'white' }} />} />
                                </div>
                            </div>
                        </div>

                        <div id="Testimonials_Wave_text_wrap">
                            <h2>Wave</h2>
                            <p>“At Wave we pride ourselves on working with partners and the market operator to put the customer at the heart of what we do and improve the market for the benefit of customers.  IDenteq’s approach to innovation and technology aligns with our objectives, they also share similar values and understand the importance of a supplier/customer relationship and strategic working.</p>
                            <p>We had been looking for an innovative approach to solve our vacancy challenge, and working with IDenteq we created something brand new in the market place.  IDenteq worked collaboratively with us to develop a cost-effective solution using technology which transformed our vacancy process, and we continue to work with them to bring more data driven processes into our operation”</p>
                            <p><strong><em>– Lissa Balmer, Director of SME Customers</em></strong></p>
                            <div id="Testimonials_Wave_link_wrap">
                                <a class="button_dark" href="/bd-portal/">Learn More</a>
                            </div>
                        </div>
                    </div>
                    {/* Wave - END */}
                    
                    {/* United Utilities */}
                    <div id="Testimonials_UU" class="testimonials_row">
                        <div id="Testimonials_UU_text_wrap">
                            <h2>United Utilities</h2>
                            <p>“The UU team prides itself on being industry leading and strives to deliver results through innovative approaches and the solutions provided by IDenteq support this ethos.</p>
                            <p>We needed to source an innovative approach to solve our void problem and deliver something different to the existing solutions I had seen in the market.</p>
                            <p>IDenteq worked closely with us and provided a new solution which ticked all our boxes.  I am thrilled with the result that this partnership has delivered for both UU and our customers”</p>
                            <p><strong><em>– Michelle Atkinson, Head of Income</em></strong></p>
                            <div id="Testimonials_UU_link_wrap">
                                <a class="button_dark" href="/cot-portal/">Learn More</a>
                            </div>
                        </div>

                        <div id="Testimonials_UU_img_wrap">
                            <div id="Testimonials_UU_img_margin">
                                <img id="Testimonials_UU_img" src="/images/united-utilities-logo.png" alt="" />
                            </div>
                        </div>
                    </div>
                    {/* United Utilities - END */}
                    
                    {/* Thames Water */}
                    <div id="Testimonials_ThamesWater" class="testimonials_row">
                        <div id="Testimonials_ThamesWater_img_wrap">
                            <div id="Testimonials_ThamesWater_img_margin">
                                <img id="Testimonials_ThamesWater_img" src="/images/thames-water-logo.png" alt="" />
                            </div>
                        </div>

                        <div id="Testimonials_ThamesWater_text_wrap">
                            <h2>Thames Water</h2>
                            <p>“At Thames Water, our core values include providing a better customer experience. Working with IDenteq and using their COT portal to manage our Void base has achieved both process efficiencies and provided an innovative, user-friendly tool for our people.</p>
                            <p>This has helped to improve our ability to accurately identify customers using our services but who aren’t currently being charged, without increasing complaints.  That’s a fantastic step in delivering these core values and limiting price increases for our customers.</p>
                            <p>We are delighted to continue this partnership with IDenteq as they work with us to achieve our core objectives.”</p>
                            <p><strong><em>– Andrew Edge, Revenue Assurance Manager</em></strong></p>
                            <br />
                            <p>“I just wanted to say, I have absolutely loved working with you – and I know that the voids will get better and better with the IDenteq solution you have in place.  The constant agile approach you offer to continuously improve the service is so refreshing. I do hope we get the opportunity to work together in the future”</p>
                            <p><strong><em>– Wendy Hearn, Revenue Assurance Team Manager</em></strong></p>
                            <div id="Testimonials_ThamesWater_link_wrap">
                                <a class="button_dark" href="/cot-portal/">Learn More</a>
                            </div>
                        </div>
                    </div>
                    {/* Thames Water - END */}

                </Container>
                {/* Testimonials content - END */}
                <div className="contact-wrap">
                    <Contact page="testimonials" />
                </div>
                <Footer />
            </div>
        );
    }
}
