import styles from "./PPForm.module.css"

import endpoints from "../../../helpers/endpoints";
import request from "../../../helpers/request";

import { logEvent } from "../../../analytics";

import Button from "../common/Button";

import { useState, useEffect } from "react";

export default function PPForm() {
    const [form, setForm] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (submitted === true) {
            setForm({})
        }
        setSubmitted(false)
    }, [submitted])

    const updateContactUsFormValue = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value ? e.target.value : undefined
        })
    }

    const updateCheckboxValue = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked ? 1 : 0
        })
    }

    const contactFormSubmit = (e) => {
        e.preventDefault();

        request().post(endpoints.PROSPECT_PORTAL_LANDING_PAGE_ALT_FORM, form).then(e => {
            setSubmitted(true)

            logEvent({
                category: "Contact",
                action: "Submit",
                label: "Contact form submitted"
            })
        }).catch(e => {
            setError(e.response.data)
        })
    }

    return (
        <form>
            {error ? (
                <div className="errorBox">
                    <p>There was a problem with your submission. Please review the fields below.</p>
                    <ul>
                        {error.errors?.first_name ? (
                            <li><strong>First Name:</strong> {error.errors?.first_name}</li>
                        ) : <></>}
                        {error.errors?.last_name ? (
                            <li><strong>Last Name:</strong> {error.errors?.last_name}</li>
                        ) : <></>}
                        {error.errors?.email ? (
                            <li><strong>Email:</strong> {error.errors?.email}</li>
                        ) : <></>}
                        {error.errors?.phone ? (
                            <li><strong>Phone:</strong> {error.errors?.phone}</li>
                        ) : <></>}
                        {error.errors?.company ? (
                            <li><strong>Company:</strong> {error.errors?.company}</li>
                        ) : <></>}
                        {error.errors?.job_title ? (
                            <li><strong>Job Title:</strong> {error.errors?.job_title}</li>
                        ) : <></>}
                    </ul>
                </div>
            ) : <></>}
            <div className={styles.formRow}><input type="text" placeholder="First Name" name="first_name" value={form?.first_name ?? ''} onChange={updateContactUsFormValue} size="1" required /></div>
            <div className={styles.formRow}><input type="text" placeholder="Last Name" name="last_name" value={form?.last_name ?? ''} onChange={updateContactUsFormValue} size="1" required /></div>
            <div className={styles.formRow}><input type="text" placeholder="Email" name="email" value={form?.email ?? ''} onChange={updateContactUsFormValue} size="1" required /></div>
            <div className={styles.formRow}><input type="text" placeholder="Phone (optional)" name="phone" value={form?.phone ?? ''} onChange={updateContactUsFormValue} size="1" /></div>
            <div className={styles.formRow}><input type="text" placeholder="Company" name="company" value={form?.company ?? ''} onChange={updateContactUsFormValue} size="1" required /></div>
            <div className={styles.formRow}><input type="text" placeholder="Job Title" name="job_title" value={form?.job_title ?? ''} onChange={updateContactUsFormValue} size="1" required /></div>
            <div className={styles.formRow}>
                <label htmlFor="pp_email_opt_in">
                    <input type="checkbox" id="pp_email_opt_in" name="email_opt_in" checked={form?.email_opt_in ?? null} onChange={updateCheckboxValue} size="1" required style={{marginInlineEnd: '0.5rem'}} />
                    I'd like to receive emails with great contents and updates.
                </label>
            </div>
            <Button onClick={contactFormSubmit} href="#" text="Submit" color="var(--highlight-color)" />
        </form>
    )
}

