import styles from "./css/Hero.module.css"

import Section from "./Section"

export default function Hero({ children, ...props }) {
    return (
        <Section className={styles.hero} {...props} dark>
            {children}
        </Section>
    )
}
