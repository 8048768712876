import styles from "./ProspectPortalLandingPage.module.css"

import Navbar from "../common/Navbar";
import Hero from "../common/Hero";
import Section from "../common/Section";
import CardRow from "../common/CardRow";
import DarkCard from "../common/DarkCard";
import CardIcon from "../common/CardIcon";
import Contact from "../common/Contact";
import Footer from "../common/Footer";
import FooterMobile from "../common/FooterMobile";
import DownloadModal from "../common/DownloadModal";
import DataSources from "../common/DataSources";
import UtilityCompanies from "../common/UtilityCompanies";
import PPForm from  "./PPForm.jsx"
import ScrollButton from "../common/ScrollButton"

export default function ProspectPortalLandingPage() {
    return (
        <div className={styles.page}>
            <Navbar />
            <Hero style={{ paddingBottom: 0 }}>
                <div style={{ paddingBottom: "var(--section-bottom-padding)" }}>
                    <div className="section__thumbprint-mask">
                        <div className={styles.thumbTopContainer} style={{paddingBottom: "var(--section-bottom-padding)"}}>
                            <div className={styles.thumbprintTop} />
                        </div>
                    </div>
                    <div className={styles.splitSection} style={{gap: '5rem'}}>
                        <div>
                            <h3><strong>Find out Prospect Portal pricing directly & trial it for free</strong></h3>
                            <p>Get 100 new, sample B2B leads</p>
                            <p>Cleanse & append up to 1k records from your database</p>
                            <h3 style={{marginTop: '2rem'}}><strong>Why sales teams are choosing Prospect Portal</strong></h3>
                            <p><strong>Data is sourced, cleansed & verified from 16 Key UK sources for blanket coverage & accuracy</strong></p>
                            <ul>
                                <li>Cleanse & enrich your existing prospect database</li>
                                <li>Quickly access new start-ups & move-ins/COTS</li>
                                <li>Build new sales data sets, filtered to your spec</li>
                                <li>Tailor made for business energy & water sales</li>
                            </ul>
                            <h3>Sell faster, smarter & boost productivity</h3>
                            <div className={styles.certLogos}>
                                <div className={styles.imgWrap}><img src="/images/prospect-portal-landing-page/img/salesforce.svg" alt="Salesforce Logo" /></div>
                                <div className={styles.imgWrap}><img src="/images/prospect-portal-landing-page/img/prospect-portal-logo.png" alt="Prospect Portal Logo" /></div>
                                <div className={styles.imgWrap}><img src="/images/bd-portal-landing-page/img/footer-icons/iso.png" alt="ISO 27001" /></div>
                            </div>
                        </div>
                        <div style={{margin: 0}}>
                            <p>Book your demo & get free data & a free data cleanse</p>
                            <PPForm />
                        </div>
                    </div>
                </div>
                <ScrollButton style={{marginTop: 0}} />
            </Hero>
            <Section className={styles.cardSection}>
                <div className="section__thumbprint-mask">
                    <div className={styles.thumbBottomContainer}>
                        <div className={styles.thumbprintBottom} />
                    </div>
                </div>
                <h2>
                    Refreshed weekly, data is cleansed, verified & appended, 
                    ready for instant download or integration into your CRM 
                    or sales process.
                </h2>
                <CardRow>
                    <DarkCard>
                        <CardIcon src="/images/prospect-portal-landing-page/icons/prospect-portal-icons/identeq-folder.svg" alt="15 quality data sources" />
                        <p>
                            Find current actively trading businesses and your 
                            perfect prospects, sourced from and cleansed against, 
                            15 key, reliable sources.
                        </p>
                    </DarkCard>
                    <DarkCard>
                        <CardIcon src="/images/prospect-portal-landing-page/icons/prospect-portal-icons/identeq-shield.svg" alt="15 quality data sources" />
                        <p>
                            Stop paying for data you can’t work effectively – 
                            remove poor quality data, saving you time, money and 
                            resources; protecting your ROI.
                        </p>
                    </DarkCard>
                    <DarkCard>
                        <CardIcon src="/images/prospect-portal-landing-page/icons/prospect-portal-icons/identeq-graph.svg" alt="15 quality data sources" />
                        <p>
                            Increase productivity and boost conversion. Use our 
                            advanced filter functionality to refine your data 
                            and find perfect matches against your buyer profile.
                        </p>
                    </DarkCard>
                </CardRow>
            </Section>
            <hr />
            <Section className={styles.downloadSection}>
                <div className={styles.splitSection}>
                    <div className={styles.left}>
                        <h2>
                            Our unique approach to capturing, cleansing & confidence 
                            scoring prospect data is a game-changer for B2B Sales.
                            <br />
                            <br />
                            Take a look today</h2>
                        <DownloadModal 
                            url="/files/Identeq Prospect Portal Overview August.pdf" 
                            text="Download more details here and qualify for a free, tailored, sample lead list" 
                            buttonText="Download"
                            page="prospect-portal"
                            hoverColor="black" 
                        />
                    </div>
                    <div className={styles.right}>
                        <img src="/images/prospect-portal-landing-page/img/prospect-ui-alt.svg" alt="" />
                    </div>
                </div>
            </Section>
            <hr />
            <Section className={styles.logosSection}>
                <h2>15 Quality data sources at your fingertips</h2>
                <DataSources />
            </Section>
            <hr />
            <Section className={styles.logosSection}>
                <h2>Some of the people we work with</h2>
                <UtilityCompanies />
            </Section>
            <Contact page="prospect-portal" />
            <Footer className={styles.desktopOnly} />
            <FooterMobile className={styles.mobileOnly} />
        </div>
    )
}
