module.exports = Object.freeze({
    BASE: process.env.REACT_APP_BASE_URL,
    DOMAIN: '/domain',
    LOGIN: '/auth/login',
    LOGOUT: '/logout',
    CREATE_USER: '/auth/create-user',
    CHANGE_OF_USE: '/changeofuse',
    PUBLICATIONS: '/publications',
    ACTIVATE: '/auth/activate',
    ACTIVATE_POST: '/auth/activate/post',
    RESET_REQUEST: '/auth/reset/request',
    RESET_CHECK: '/auth/reset/check',
    RESET_RESET: '/auth/reset',
    CONTACT_US: '/contact-us',
    ARRANGE_DEMO: '/arrange-demo',
    CAREERS_FORM: '/careers-form',
    BD_PORTAL_LANDING_PAGE_FORM: '/bd-portal-landing-page',
    ADDRESS_PORTAL_LANDING_PAGE_FORM: '/address-portal-landing-page',
    PROSPECT_PORTAL_LANDING_PAGE_FORM: '/prospect-portal-landing-page',
    PROSPECT_PORTAL_LANDING_PAGE_ALT_FORM: '/prospect-portal-landing-page-form',
    MODULES: '/modules',
    DOWNLOAD_FORM: '/download-form'
})
